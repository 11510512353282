<script setup>
import { usePage } from '@inertiajs/vue3';
import { ref, watch } from 'vue';

const { isBasket, totalItems: initialTotalItems } = usePage().props;

const totalItems = ref(initialTotalItems);

watch(() => usePage().props.totalItems, (newVal) => {
    totalItems.value = newVal;
}, {
    immediate: true
})
</script>

<template>
    <v-btn v-if="!isBasket" :href="route('checkout.basket')" variant="text" class="font-size-18">
        <template v-slot:default>
            <v-icon size="26" color="text" icon="fa fa-basket-shopping"/>
            <p class="total-items">{{ totalItems ?? 0 }}</p>
        </template>
    </v-btn>
</template>

<style scoped>
.total-items {
    position: absolute;
    top: 53%;
    left: 53%;
    transform: translate(-50%, -50%);
    margin: 0;
    color: rgb(var(--v-theme-accent));
    font-weight: 500;
    font-size: 24px;
}
</style>
